import React from 'react';
import './App.css'; // Importando o arquivo CSS
import myImage from './image/logo.png';

function App() {
  return (
    <div className="App">
      <img src={myImage} alt="Silk Secret" className="centered-image" />
    </div>
  );
}

export default App;
